const dateRequired = (propertyType) => (v) => {
  if ((v === '' && !v.length) || (v && v.length === 10)) {
    return true;
  }

  return `Field '${propertyType}' must be full completed`;
};

const dateValidation = (propertyType) => (v) => {
  if (v === '' && !v.length) {
    return true;
  }

  if (v) {
    const dateSplit = v.split('-');

    const year = dateSplit[0];
    const month = parseInt(dateSplit[1], 10);
    const day = parseInt(dateSplit[2], 10);

    const yearValidation = Boolean(year >= 1000);
    const monthValidation = Boolean(month >= 1 && month <= 12);
    const dayValidation = Boolean(day >= 1 && day <= 31);

    const isDateValid = Boolean(yearValidation && monthValidation && dayValidation);

    if (isDateValid) {
      return true;
    }
  }

  return `Field '${propertyType}' is not valid`;
};

export {
  dateRequired,
  dateValidation,
};
